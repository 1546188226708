import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Instagram } from 'lucide-react';
import { useInView } from 'react-intersection-observer';

const InstagramFeed = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <section className="bg-stone-100 py-4">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-center gap-4 mb-4 group">
          <Instagram className="w-8 h-8 text-stone-900 group-hover:text-amber-500 transition-colors" />
          <a
            href="https://www.instagram.com/ploughmansdeli"
            target="_blank"
            rel="noopener noreferrer"
            className="text-3xl font-semibold text-center text-stone-900 group-hover:text-amber-500 transition-colors"
          >
            Follow Our Quest
          </a>
        </div>

        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
          className="aspect-[3/4] md:aspect-[16/9] w-full"
        >
          <iframe
            src="https://www.instagram.com/ploughmansdeli/embed"
            // className="w-full h-[568px] border-none rounded-lg shadow-lg" 
            className="w-full h-full border-none rounded-lg shadow-lg"
            loading="lazy"                                              
            title="Ploughman's Deli Instagram Feed"                    
          />
        </motion.div>

      </div>
    </section>
  );
};

export default InstagramFeed;